.tooltip-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  z-index: 999999;
}

.tooltip-container svg {
  margin-bottom: 6px;
}

.tooltip-popup {
  position: absolute;
  top: -30px;
  font-style: normal;
  font-weight: normal;
  background: #212529;
  color: white !important;
  padding: 6px 10px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 100%;
  opacity: 0;
  transition: all 200ms ease;
  transition-delay: 200ms;
  z-index: 999999;
  width: fit-content;
  text-align: center;
}

.tooltip-container:hover .tooltip-popup {
  opacity: 0.9;
}