.ShortlistMenu-container {
  position: absolute;
  top: 12px;
  right: 0;
  animation: slideInFromRight 1s ease-in-out 1s;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-flow: column nowrap;
  z-index: 999;
}

.ShortlistMenu-container.ShortlistMenu-container--offset-right {
  right: 8px;
}

.ShortlistMenu-toggle {
  cursor: pointer;
  background: white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 5px 0px 0px 5px;
  padding: 8px 20px 8px 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
}

.ShortlistMenu-toggle-image {
  height: 15px;
  width: auto;
  margin-right: 5px;
}

.ShortlistMenu-item-label,
.ShortlistMenu-toggle-count {
  display: inline-block;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: black;
  margin: 0;
}

.ShortlistMenu-menu {
  margin-right: -100vw;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-flow: column nowrap;
  transition: all 300ms;
}

.ShortlistMenu-menu--visible {
  margin-right: 15px;
}

.ShortlistMenu-item {
  cursor: pointer;
  background: white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 8px 15px;
  margin-top: 8px;
  display: inline-block;
}

@keyframes slideInFromRight {
  from {
    right: -100%;
  }

  to {
    right: 0;
  }
}

@media screen and ( min-width: 900px ) {
  .ShortlistMenu-menu--visible {
    margin-right: 8px;
  }

  .ShortlistMenu-toggle {
    padding: 8px 15px;
  }
}
