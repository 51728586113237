#planpoint-root {
  font-weight: normal;
  position: relative;
  z-index: 999;
}

#planpoint-root .App {
  position: relative;
  color: #212529;
}

#planpoint-root .App .Exterior {
  transition: all 250ms ease;
  height: 650px; /* default height (will be overridden) */
}

#planpoint-root .App .Unit {
  transition: all 250ms ease;
}

#planpoint-root .App .planpoint-sidebar {
  background: white;
  z-index: 1000;
  overflow: auto;
}

/* full height on smaller breakpoints */
@media (max-width: 991px) {
  #planpoint-root .App .Exterior {
    height: 100% !important;
  }

  #planpoint-root .App .planpoint-sidebar {
    height: 500px;
  }

  #planpoint-root .App .planpoint-sidebar .custom-scrollbar {
    height: 500px !important;
  }
}

#planpoint-root .App .main-image {
  z-index: -1;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

#planpoint-root .App .main-image--visible {
  z-index: 1;
  opacity: 1;
  position: relative;
  width: 100%;
  height: auto;
}

#planpoint-root .App #konva-container {
  background: #f7f7f7;
  /* background-position: center center; */
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-size: contain;
  background-position: top left;
}

#planpoint-root .App .icon {
  height: 0.8em;
}

#planpoint-root .App .icon-flip-v {
  transform: rotate(180deg);
}

#planpoint-root .App th[scope="col"] {
  cursor: pointer;
  border-top: none;
}

#planpoint-root .App .badge {
  padding: 2px 12px;
  font-family: Inter, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-transform: capitalize;
}

#planpoint-root .App .badge-primary {
  background-color: rgba(35, 89, 227, 0.2);
}

#planpoint-root .App .badge-danger {
  background-color: rgba(227, 41, 35, 0.2);
}

#planpoint-root .App .table-filter {
  /* padding: 5px 10px 10px; */
  border-radius: 8px;
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  transition: all 250ms ease;
}

#planpoint-root .App .table-filter:hover {
  background: white;
  border: 1px solid #dfdfdf;
}

#planpoint-root .App .table-filter .form-label {
  color: #717171;
  font-size: 11px;
  line-height: 10px;
  margin-bottom: 5px;
  margin-bottom: -10px;
  z-index: 2;
  position: absolute;
  margin-left: 10px;
  margin-top: 10px;
}

#planpoint-root .App .table-filter select {
  border: none;
  background: none;
  padding: 0;
  height: auto;
  font-weight: 600;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  /* NEW */
  padding: 10px;
  padding-top: 22px;
  background: f7f7f7;
  margin-top: -20px;
}

#planpoint-root .App .unit-row {
  cursor: pointer;
  transition: background 250ms ease;
}

#planpoint-root .App .unit-row:hover {
  background: #f5f5f5;
}

#planpoint-root .App .panel-navigation {
  cursor: pointer;
  position: absolute;
  left: 25px;
  top: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: background-color 250ms ease;
  transition: background-color 250ms ease;
  z-index: 10;
}

#planpoint-root .App .panel-navigation:hover {
  background-color: rgba(0, 0, 0, 0.75);
}

#planpoint-root .App .panel-navigation img {
  height: 16px;
}

#planpoint-root .App .panel-branding {
  cursor: pointer;
  position: absolute;
  left: 15px;
  bottom: 10px;
  z-index: 10;
  opacity: 0;
  transition: all 500ms ease;
  padding: 6px 12px;
  background: white;
  border-radius: 6px;
}

#planpoint-root .App .panel-branding img {
  height: 25px;
}

#planpoint-root .App .loading-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0;
  z-index: 999;
  transition: all 250ms;
  visibility: hidden;
}

#planpoint-root .App .loading-overlay--active {
  opacity: 1;
  visibility: visible;
  transition: all 250ms;
}

#planpoint-root .App .unit-action:hover {
  background: white;
  border: 1px solid #dfdfdf;
  transition: all 250ms ease;
}

#planpoint-root .App .grid-list-icon,
#planpoint-root .App .grid-list-icon svg {
  display: inline-block;
  height: 15px;
  width: auto;
  cursor: pointer;
  color: #ccc;
}

#planpoint-root .App .grid-list-icon-list {
  height: 15px;
  width: auto;
  cursor: pointer;
}

#planpoint-root .App .unit-card {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  cursor: pointer;
}

#planpoint-root .App .unit-card small {
  font-size: 0.75em;
}

#planpoint-root .App .track-vertical {
  top: 2px;
  bottom: 2px;
  display: block !important;
  right: 0;
  width: 15px !important;
  background: white;
  z-index: 2147483646;
}

#planpoint-root .App .track-vertical .thumb-vertical {
  position: relative;
  display: block;
  height: 50px;
  width: 50% !important;
  margin-left: auto;
  cursor: pointer;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #dfdfdf;
}

#planpoint-root .App .react-select__menu {
  z-index: 999 !important;
}

.tooltip {
  background: #212529;
  color: white;
  padding: 6px 10px;
  margin-bottom: 12px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 100%;
  opacity: 0.9;
  position: relative;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #212529 transparent transparent transparent;
}

#planpoint-root .App .table th,
#planpoint-root .App .table td {
  background: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  white-space: nowrap !important;
}

#planpoint-root .App .unit-list {
  overflow: auto;
  /* max-height: 115vh; */
}

/* DISABLE SCROLLBARS */

#planpoint-root .App * {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#planpoint-root .App *::-webkit-scrollbar {
  display: none;
}

@media (max-width: 600px) {
  #planpoint-root .App .table th,
  #planpoint-root .App .table td {
    padding: 0.75rem 0.25rem;
    font-size: 0.7rem;
  }

  #planpoint-root .App .badge {
    font-size: 0.7rem;
  }

  #planpoint-root .App .panel-branding {
    padding: 3px 6px;
    border-radius: 6px;
  }
}