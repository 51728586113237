.RangeSliderRow {
  padding-top: 20px;
  padding-bottom: 36px;
  margin-bottom: 24px;
  padding-left: 10px;
  padding-right: 10px;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}

.rsr-slider-label {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: #717171;
}

.rsr-slider-label span {
  font-weight: 600;
  color: #222529;
}

.rsr-slider-container {
  margin-top: 8px;
}

.rsr-slider {
  position: relative;
  width: 90%;
}

.rsr-slider-track,
.rsr-slider-range {
  border-radius: 12px;
  height: 12px;
  position: absolute;
}

.rsr-slider-track {
  background-color: #F5F5F5;
  width: 100%;
  z-index: 1;
}

.rsr-slider-range {
  background-color: #222529;
  z-index: 2;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: calc(90% - 10px);
  outline: none;
}

/* Left and right thumbs */
.thumb::-webkit-slider-thumb {
  background: white;
  border: 1px solid #222529;
  border-radius: 50%;
  box-shadow: none;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 12px;
  pointer-events: all;
  position: relative;
}

.thumb-left::-webkit-slider-thumb {
  z-index: 3;
}

.thumb-right::-webkit-slider-thumb {
  z-index: 4;
}
