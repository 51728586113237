#planpoint-root .App .Modal__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 2147483647;
  display: flex;
  padding: 40px 20px;
  align-items: flex-start;
  justify-content: center;
  -webkit-transition: opacity 300ms ease-in;
  -moz-transition: opacity 300ms ease-in;
  -o-transition: opacity 300ms ease-in;
  -ms-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in;
  -webkit-animation: fadein 300ms; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 300ms; /* Firefox < 16 */
  -ms-animation: fadein 300ms; /* Internet Explorer */
  -o-animation: fadein 300ms; /* Opera < 12.1 */
  animation: fadein 300ms;
  overflow: auto;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

#planpoint-root .App .Modal__overlay .card {
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
}

#planpoint-root .App .Modal__overlay .card .Modal__close {
  position: fixed;
  right: 18px;
  top: 18px;
  cursor: pointer;
  opacity: 0.7;
}

#planpoint-root .App .Modal__overlay .card .Modal__close:hover {
  opacity: 1;
}

#planpoint-root .App .EmbedModal.Modal__overlay {
  padding: 0;
  background: black;
  z-index: 2147483647;
}

.EmbedModal .Modal__inner {
  width: 100%;
  height: 100%;
}

.EmbedModal .Modal__toolbar {
  text-align: right;
  padding: 12px 8px;
}

.EmbedModal .Modal__toolbar img {
  filter: invert();
  height: 16px;
}

#planpoint-root .App .EmbedModal .Modal__content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#planpoint-root .App .EmbedModal .Modal__content iframe {
  width: 100%;
  height: 100%;
}

.ReactModalPortal .btn-finish-option {
  display: inline-block;
  font-weight: 500;
  color: #f7f7f7;
  background-color: #222;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  transition: all .15s ease-in-out;
  margin-left: 6px !important;
}

.ReactModalPortal .btn-finish-option:hover,
.ReactModalPortal .btn-finish-option--active {
  color: #222;
  background-color: #f7f7f7;
}

@media screen and ( min-width: 600px ) {
  #planpoint-root .App .Modal__overlay:not(.Modal__overlay--high):not(.Modal__overlay--full) {
    padding: 20vh 6% 6%;
  }

  #planpoint-root .App .Modal__overlay .card {
    max-width: 70%;
  }

  #planpoint-root .App .Modal__overlay .card.modal--slim {
    max-width: 500px;
    color: #222;
  }
}

@media screen and ( min-width: 900px ) {
  #planpoint-root .App .Modal__overlay .card {
    max-width: 60%;
  }
}
