/* Navigation */
.Collection-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.Collection-nav-item {
  flex: 1;
  padding-right: 36px;
}

.Collection-nav-item:last-of-type {
  flex: 1;
  padding-right: 0;
}

input.Collection-nav-input {
  background: none;
  border: none;
  border-bottom: 2px solid #222;
  box-shadow: none;
  outline: none;
  width: 100%;
  font-family: "Inter", sans-serif;
  color: #222 !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  font-weight: 600;
  padding: 8px 4px;
}

input.Collection-nav-input::-webkit-outer-spin-button,
input.Collection-nav-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.Collection-nav-input[type=number] {
  -moz-appearance: textfield;
}

input.Collection-nav-input::placeholder,
input.Collection-nav-input::-moz-placeholder,
input.Collection-nav-input::-webkit-input-placeholder {
  color: #222 !important;
}

.Collection-nav-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.Collection-nav-button {
  flex: 1;
  color: #222;
  font-size: 18px !important;
  line-height: 200% !important;
  font-weight: 600;
  border: 3px solid #222;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  transition: all 300ms ease;
  cursor: pointer;
}

.Collection-nav-button-label {
  margin-left: 6px;
}

.Collection-nav-button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  flex: 1;
  margin-left: 24px;
}

.Collection-nav-button-group .Collection-nav-button:first-of-type {
  border-right: none;
}

.Collection-nav-button-group .Collection-nav-button:last-of-type {
}

.Collection-nav-button:hover,
.Collection-nav-button.Collection-nav-button--active {
  background: #222;
  color: white;
}

.Collection-meta h5 {
  font-family: Inter, sans-serif;
  color: #222;
  font-size: 16px !important;
  line-height: 200% !important;
  font-weight: 600;
  margin: 0;
}

.Collection-meta {
  margin-top: 18px;
  margin-bottom: 24px;
}

/* MapBox Style adjustments */

.marker {
  background-image: url('../../assets/planpoint_logo_white.svg');
  background-size: 75%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #2418ab;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
}

.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.marker-cover {
  width: 100%;
  height: auto;
  margin-bottom: 12px;
}

.marker-title {
  font-family: 'Inter', sans-serif;
  color: #222;
  font-size: 16px !important;
  line-height: 200% !important;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
}

.marker-description {
  font-family: 'Inter', sans-serif;
  color: #222;
  font-size: 14px !important;
  line-height: 130% !important;
  font-weight: 600;
  margin-bottom: 0;
  opacity: 0.5;
  text-align: center !important;
}

.marker-content {
  cursor: pointer;
}

/* Medium devices (landscape phones & tablets, 768px and up) */
@media screen and (max-width: 992px) {
  .Collection-navbar {
    flex-flow: row wrap;
  }
  
  .Collection-nav-item,
  .Collection-nav-item:last-of-type {
    flex: auto;
    width: calc(50% - 24px);
    padding-right: 24px;
    margin-bottom: 36px;
  }

  .Collection-nav-item:nth-of-type(2n) {
    padding-right: 0;
  }

  .Collection-nav-button {
    font-size: 16px !important;
    height: 48px;
    padding: 6px 0px;
    background: white;
  }
  
  .Collection-nav-button-label {
    margin-left: 6px;
  }
  
  .Collection-nav-button-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row nowrap;
    flex: 1;
    margin-left: 18px;
  }

  .Collection-meta {
    margin-top: 0;
    margin-bottom: 36px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (max-width: 576px) {
  .Collection-navbar {
    flex-flow: row wrap;
  }
  
  .Collection-nav-item,
  .Collection-nav-item:last-of-type {
    width: 100%;
    padding-right: 0;
    margin-bottom: 36px;
  }

  .Collection-nav-button {
    font-size: 16px !important;
    height: 48px;
    padding: 6px 0px;
  }
  
  .Collection-nav-button-label {
    margin-left: 6px;
  }
  
  .Collection-nav-button-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row nowrap;
    flex: 1;
    margin-left: 18px;
  }
}